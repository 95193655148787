<template>
  <div class="app-wrapper">
    <navbar />
    <div class="main-container">
      <el-row>
        <el-col v-if="sidebar.opened" :span="sidebar.opened ? 3 : 0">
          <sidebar class="sidebar-container" />
        </el-col>
        <el-col :span="sidebar.opened ? 21 : 24">
          <app-main />
        </el-col>
      </el-row>
      <el-tooltip :content="`${!showSideBar ? '显示' : '隐藏'}左侧菜单栏`">
        <div ref="controlSidebarRef" class="control-sidebar" @click="controlSideBar">
          <i v-if="showSideBar" class="el-icon-d-arrow-left"></i
          ><i v-else class="el-icon-d-arrow-right"></i>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from "./components";

export default {
  name: "Layout",
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    showSideBar: {
      get() {
        return this.sidebar.opened;
      },
      set() {
        this.$store.dispatch("app/TOGGLE_SIDEBAR"); //防止vuex与v-model结合使用时提示属性可以获取但未设置
      }
    }
  },
  methods: {
    controlSideBar(val) {
      this.$store.dispatch("app/toggleSideBar");
    }
  },
  async mounted() {
    // await this.$store.dispatch("app/openSideBar");
    // 多窗口打开不同账户
    let id = window.location.href.split("?id=")[1];
    if (id) {
      this.$bus.$emit("trigger-getAdAccounts", { obj: { id } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}
.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}
.mobile .fixed-header {
  width: 100%;
}
.control-sidebar {
  position: fixed;
  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
  }
  bottom: 32px;
  left: 20px;
  z-index: 100000;
}
</style>
