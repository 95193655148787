<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
import variables from '@/styles/variables.scss';

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      let arr = _.cloneDeep(this.$router.options.routes);
      console.log('----this.$router.options.routes', this.$router.options.routes);
      return this.filterRoutes(arr);
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  mounted() {
    // console.log(this.$router.options.routes)
    // console.log(this.$router.options.routes[0].hidden)
  },
  methods: {
    //自动规则发布预览版，不配置左边菜单，但是需要有路径可以访问
    filterRoutes(routes) {
      return routes.filter((route) => {
        // 如果有子路由，递归过滤
        if (route.children) {
          route.children = this.filterRoutes(route.children);
        }
        // 如果meta不存在，或者hideInMenu不存在，或者hideInMenu不为false，则返回true
        return !route.meta || route.meta.hideInMenu === undefined || route.meta.hideInMenu !== false;
      });
    },
  },
};
</script>
<style lang="scss">
.scrollbar-wrapper.el-scrollbar__wrap {
  // overflow: auto;
  height: calc(100vh - 80px);
}
</style>
